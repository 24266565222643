import React, { useEffect, useState } from "react";
import Form from "../../utils/Form";
import Pagination from "../../utils/Pagination";
import Table from "../../utils/Table";
import style from './Clienti.module.css';
const Clienti = (props) => {

    const [btnAdd, setBtnAdd] = useState(0);
    const [clients, setClients] = useState([]);
    const [realClientsNumber, setRealClientsNumber] = useState(0)
    const [offset, setOffset] = useState(0);
    const [client, setClient] = useState([]);

    useEffect(() => {
        fetch('https://admin-ddspartners.teawebtest.it/admin-ddspartners-webserver/public/api/users')
            .then((response) => response.json())
            .then((data) => {
                let fetchData = data.map((row)=>{
                    return {
                        id: row.id,
                        nome: row.nome,
                        cognome: row.cognome,
                        indirizzo: row.indirizzo,
                        email: row.email,
                        attivazione: row.stato
                    }
                })
                setClients(fetchData.slice(offset, offset+5))
                setRealClientsNumber(data.length)
            })
    }, [offset, setClients])

    let columnName = ['Nome', 'Cognome', 'indirizzo', 'email', 'attivazione', 'Strumenti']

    function add() {
        setBtnAdd(1)
        setClient([])
    }

    function closeAdd() {
        setBtnAdd(0)
    }

    function saveItem(e) {
        e.preventDefault()
        setBtnAdd(0)
    }

    function elimina(id) {
        alert("Funzione elimina per l'elemento con id = " + id + " ancora da implementare")
    }

    function modifica(id) {
        setBtnAdd(1)
        setClient(()=>{
            let c = clients.map((client)=>{
                if(client.id === id){
                    return client;
                }
                return 0
            })
            let cli = {}
            for(let i = 0; i < c.length; i++){
                if(c[i] !== 0){
                    cli = c[i];
                }
            }
            return cli;
        })
    }

    let FormElementGenerale = [
        { id: "nome", type: "text", labelText: "Nome" },
        { id: "cognome", type: "text", labelText: "Cognome" },
        { id: "cod_fisc", type: "text", labelText: "Codice fiscale" }
    ]

    let FormElementIndirizzo = [
        { id: "indirizzo", type: "text", labelText: "Indirizzo" },
        { id: "comune", type: "text", labelText: "Comune" },
        { id: "provincia", type: "text", labelText: "Provincia" },
        { id: "paese", type: "text", labelText: "Paese" },
        { id: "lat", type: "text", labelText: "Latitudine" },
        { id: "lng", type: "text", labelText: "Longitudine" }
    ]

    let FormElementAccount = [
        { id: "username", type: "text", labelText: "Username" },
        { id: "email", type: "text", labelText: "E-mail" },
        { id: "password", type: "text", labelText: "Password" }
    ]

    let FormElementAccettazione = [
        { id: "privacy", type: "checkbox", labelText: "Accetta la Privacy" },
        { id: "vivieco", type: "checkbox", labelText: "Partecipa a Vivieco" },
    ]

    let formElements = [
        { styleContainer: "general", title: "General", inputs: FormElementGenerale },
        { styleContainer: "indirizzo", title: "indirizzo", inputs: FormElementIndirizzo },
        { styleContainer: "account", title: "Account", inputs: FormElementAccount },
        { styleContainer: "accettazione", title: "Accettazione", inputs: FormElementAccettazione }
    ]

    function goTo(n){
        if(n < 0){
            if(offset > 0){
                setOffset(offset+n)
            }
        }else if(n > 0){
            if(offset < realClientsNumber - 5){
                setOffset(offset+n)
            }
        }
    }

    return (
        <div className={props.page === 2 ? "" : "hidden"}>
            {btnAdd ? <Form data={client} btnAdd={btnAdd} onClose={closeAdd} onSave={saveItem} formElemnts={formElements} />: 
            <>
                <button id="add-button-cli" className={style["add-button"]} onClick={add}>Nuovo Clienti</button>
                <Table elimina={(id) => elimina(id)} modifica={(id) => modifica(id)} columnName={columnName} data={clients} />
                <Pagination onClickBtn={(n)=>{goTo(n)}} num={offset} numTot={realClientsNumber} />
            </>}
        </div>
    )
}

export default Clienti