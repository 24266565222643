import React, { useEffect } from "react";
import Clienti from "./clienti/Clienti";
import Concessionari from "./concessionari/Concessionari";
import Dashboard from "./dashboard/Dashboard";
import News from "./News/News";
import Offerte from "./offerte/Offerte";
import style from './Page.module.css';

const Page = (props) => {

    useEffect(() => {

    }, [props.fetchData])

    if (props.page === 0) {
        return (
            <div className={style['page-container']}>
                <Dashboard page={props.page} />
            </div>
        )
    } else if (props.page === 1) {
        return (
            <div className={style['page-container']}>
                <Concessionari page={props.page} />
            </div>
        )
    } else if (props.page === 2) {
        return (
            <div className={style['page-container']}>
                <Clienti page={props.page} />
            </div>
        )
    } else if (props.page === 3) {
        return (
            <div className={style['page-container']}>
                <News page={props.page} />
            </div>
        )
    } else if (props.page === 4) {
        return (
            <div className={style['page-container']}>
                <Offerte page={props.page} />
            </div>
        )
    }
}

export default Page
