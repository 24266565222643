import React from "react";
import { useState, useEffect } from "react";
import Clock from "./Clock";
import style from './css/TopBar.module.css';
const TopBar = (props)=>{

    const [puntiVivieco, setPuntiVivieco] = useState(0)

    function getPuntiVivieco(punti) {
        setPuntiVivieco(()=>{
            return punti;
            
        })
    }

    function getTitle(){
        let title = "";
        switch (props.title) {
            case 0:
                title = "Dashboard";
                break;
            case 1:
                title = "Concessionari";
                break;
            case 2:
                title = "Clienti";
                break;
            case 3:
                title = "News";
                break;
            case 4:
                title = "Offerte";
                break;
            default:
                title = "Dashboard";
                break;
        }
        return title;
    }

    useEffect(() => {
        let punti = 500
        getPuntiVivieco(punti)
      }, []);

    return(
        <div className={style['top-bar-container']}>
            <div className={style['punti-vivieco']}>Punti Vivieco: {puntiVivieco}</div>
            <div className={style['title-page']}>{getTitle()}</div>
            <Clock />    
        </div>
    )
}

export default TopBar