import React, { useEffect, useState } from "react";
import style from './css/Form.module.css';
import FormElem from "./FormElem";
const Form = (props) => {

    const [data, setData] = useState(false)

    useEffect(()=>{
        if(typeof(props.data) === 'object' && !Array.isArray(props.data)){
            console.log(props.data)
            setData(true)
        }else{
            setData(false)
        }
    }, [props.data])

    return (
        <div className={props.btnAdd === 1 ? style['form-container'] : "hidden"}>
            <form>

                {props.formElemnts.map((formElement)=>{
                    return (<FormElem data={data ? props.data : {}} key={formElement.title} styleContainer={formElement.styleContainer} title={formElement.title} inputs={formElement.inputs}/>)
                })}

                <button type="submit" className={style['btn-salva']} onClick={props.onSave}>SALVA</button>
            </form>
            <button className={style['btn-close-form']} onClick={props.onClose}>X</button>
        </div>
    )
}

export default Form