import React from "react";
import style from "./css/Pagination.module.css"

const Pagination = (props) => {

    return (
        <div className={style['pagination-container']}>
            <button onClick={()=>{props.onClickBtn(-5)}}>&larr;</button>
            <div className={style["num"]}>{(props.num/5)+1}</div>
            
            <button onClick={()=>{props.onClickBtn(+5)}}>&rarr;</button>
        </div>
    )
}

export default Pagination