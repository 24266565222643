import { useState, useEffect } from 'react';
import "./css/Clock.css"

function Clock(){
  const [date, setDate] = useState(new Date());
  
const refreshClock = ()=>{
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className='clock'>
        <div className='clock-date'>{date.toLocaleDateString()}</div>
        <div className='clock-time'>{date.toLocaleTimeString()}</div>
    </div>
  );
}
export default Clock;