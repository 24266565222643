import React from "react";
import style from './css/MenuFolder.module.css';
const MenuFolder = (props)=>{

    function createElementImg(src){
        return(<img className={style['img-menu']} alt="" src={src} />)
    }

    return(
        <li className={style['menuFolder-container']} onClick={()=>props.renderPage(props.page)}>
            <div id={props.title.toLowerCase().slice(0, 3)} className={style['title']}>{props.toogle === false? props.title : createElementImg(props.src)}</div>
            <div className={style['counter']} >{props.toogle === false && props.title !== "Logout" && props.title !== "Dashboard"? "0" : ""}</div>
        </li>
    )
}

export default MenuFolder