import React, { useState } from "react";
import Form from "../../utils/Form";
import Table from "../../utils/Table";
import style from './Concessionari.module.css';
const Concessionari = (props) => {

    const [btnAdd, setBtnAdd] = useState(0);

    let columnName = ['Nome', 'indirizzo', 'email', 'attivazione', 'Strumenti']
    let concessionari = [
        { id: 0, Nome: "Conc 1", indirizzo: "via roma 1", email: "conc1@conc.com", attivazione: 1 },
        { id: 1, Nome: "Conc 2", indirizzo: "via milano 2", email: "conc2@conc.com", attivazione: 0 },
        { id: 2, Nome: "Conc 3", indirizzo: "via napoli 3", email: "conc3@conc.com", attivazione: 0 },
        { id: 3, Nome: "Conc 4", indirizzo: "via como 4", email: "conc4@conc.com", attivazione: 1 },
    ]

    function add() {
        setBtnAdd(1)
    }

    function closeAdd() {
        setBtnAdd(0)
    }

    function saveItem(e) {
        e.preventDefault()
        setBtnAdd(0)
    }

    function elimina(id) {
        alert("Funzione elimina per l'elemento con id = " + id  + " ancora da implementare")
     }
 
     function modifica(id) {
         document.getElementById('add-button-con').click()
     }

    let FormElementLogo = [
        {id: "logo", type: "text", labelText: "Nome del Logo"}
    ]

    let FormElementGenerale = [
        {id: "nome", type: "text", labelText: "Nome"},
        {id: "cognome", type: "text", labelText: "Cognome"}, 
        {id: "rag_soc", type: "text", labelText: "Ragione sociale"}, 
        {id: "cod_fisc", type: "text", labelText: "Codice fiscale"}, 
        {id: "p_iva", type: "text", labelText: "Partita IVA"}
    ]

    let FormElementIndirizzo = [
        {id: "indirizzo", type: "text", labelText: "Indirizzo"}, 
        {id: "comune", type: "text", labelText: "Comune"},
        {id: "provincia", type: "text", labelText: "Provincia"},
        {id: "paese", type: "text", labelText: "Paese"},
        {id: "lat", type: "text", labelText: "Latitudine"},
        {id: "lng", type: "text", labelText: "Longitudine"}
    ]

    let FormElementAccount = [
        {id: "username", type: "text", labelText: "Username"}, 
        {id: "email", type: "text", labelText: "E-mail"}, 
        {id: "password", type: "text", labelText: "Password"},
        {id: "tipo", type: "text", labelText: "Tipo"}
    ]

    let FormElementAccettazione = [
        {id: "privacy", type: "checkbox", labelText: "Accetta la Privacy"},
        {id: "vivieco", type: "checkbox", labelText: "Partecipa a Vivieco"},
    ]

    let formElements = [
        {styleContainer: "logo", title: "Logo", inputs: FormElementLogo},
        {styleContainer: "general", title: "General", inputs: FormElementGenerale},
        {styleContainer: "indirizzo", title: "indirizzo", inputs: FormElementIndirizzo},
        {styleContainer: "account", title: "Account", inputs: FormElementAccount},
        {styleContainer: "accettazione", title: "Accettazione", inputs: FormElementAccettazione}
    ]

    return (
        <div className={props.page === 1 ? "" : "hidden"}>
            <button id="add-button-con" className={style["add-button"]} onClick={add}>Nuovo Concessionario</button>
            <Table elimina={(id)=>elimina(id)} modifica={(id)=>modifica(id)} columnName={columnName} data={concessionari} />
            <Form btnAdd={btnAdd} onClose={closeAdd} onSave = {saveItem} formElemnts={formElements}/>
        </div>
    )
}

export default Concessionari