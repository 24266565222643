import React from "react";
import style from './css/FormElem.module.css';
import Input from "./Input";
const FormElem = (props) => {
    return (
        <div className={style[`${props.styleContainer}`] + " " + style['container-input']}>
            <div className={style['title']}>{props.title}</div>
            {props.inputs.map((input)=>{
                return (<Input key={input.id} id={input.id} type={input.type} labelText={input.labelText} data ={props.data}/>)
            })}
        </div>
    )
}

export default FormElem