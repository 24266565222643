import React from "react";
import style from './ShortcutCard.module.css';
const ShortcutCard = (props)=>{
    return(
        <div className={style['container']}>
            <img className={style['img']} src={props.src} alt={props.what}></img>
            {props.what}
            <button className={style['btn-custom']} onClick={props.doAction}>{props.action}</button>
        </div>
    )
}

export default ShortcutCard