import React, { useState } from "react";
import Form from "../../utils/Form";
import Table from "../../utils/Table";
import style from './News.module.css';
const News = (props)=>{

    const [btnAdd, setBtnAdd] = useState(0);

    let columnName = ['Nome', 'Attivazione', 'Strumenti']
    let news = [
        {id: 0, Nome: "News 1", attivazione: 1},
        {id: 1, Nome: "News 2", attivazione: 0},
        {id: 2, Nome: "News 3", attivazione: 1},
        {id: 3, Nome: "News 4", attivazione: 0},
    ]

    function add() {
        setBtnAdd(1)
    }

    function closeAdd() {
        setBtnAdd(0)
    }

    function saveItem(e) {
        e.preventDefault()
        setBtnAdd(0)
    }

    function elimina(id) {
        alert("Funzione elimina per l'elemento con id = " + id  + " ancora da implementare")
     }
 
     function modifica(id) {
         document.getElementById('add-button-new').click()
     }

    let FormElementNews = [
        {id: "nome", type: "text", labelText: "Nome"},
        {id: "testo", type: "text", labelText: "testo"}
    ]

    let formElements = [
        {styleContainer: "news", title: "News", inputs: FormElementNews},
    ]

    return(
        <div className={props.page === 3? "" : "hidden"}>
            <button id="add-button-new" className={style["add-button"]} onClick={add}>Nuova News</button>
            <Table elimina={(id)=>elimina(id)} modifica={(id)=>modifica(id)} columnName={columnName} data={news}/>
            <Form btnAdd={btnAdd} onClose={closeAdd} onSave = {saveItem} formElemnts={formElements}/>
        </div>
    )
}

export default News