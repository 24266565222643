import React, { useEffect, useState } from "react";
import style from './css/Input.module.css';
const Input = (props) => {

    const [value, setValue] = useState("")

    useEffect(()=>{
        setValue(Object.keys(props.data) === 0 ? "ff" : props.data[props.id])
    }, [props])

    console.log(props.id)
    console.log(props.data)
    return (
        <div className={style['input-container']}>
            <label htmlFor={props.id}>{props.labelText}</label>
            <input type={props.type} id={props.id} name={props.id} required value={value} ></input>
        </div>
    )
}

export default Input