import React, { useState } from "react";
import Form from "../../utils/Form";
import Table from "../../utils/Table";
import style from'./Offerte.module.css';
const Offerte = (props)=>{

    const [btnAdd, setBtnAdd] = useState(0);

    let columnName = ['Nome', 'Attivazione', 'Strumenti']
    let offerte = [
        {id: 0, Nome: "Offerta 1", attivazione: 1},
        {id: 1, Nome: "Offerta 2", attivazione: 0},
        {id: 2, Nome: "Offerta 3", attivazione: 1},
        {id: 3, Nome: "Offerta 4", attivazione: 0},
    ]

    function add() {
        setBtnAdd(1)
    }

    function closeAdd() {
        setBtnAdd(0)
    }

    function saveItem(e) {
        e.preventDefault()
        setBtnAdd(0)
    }

    function elimina(id) {
        alert("Funzione elimina per l'elemento con id = " + id  + " ancora da implementare")
     }
 
     function modifica(id) {
         document.getElementById('add-button-off').click()
     }

    let FormElementOfferte = [
        {id: "nome", type: "text", labelText: "Nome"},
        {id: "testo", type: "text", labelText: "testo"},
        {id: "prezzo", type: "number", labelText: "Prezzo (€)"},
    ]

    let formElements = [
        {styleContainer: "offerta", title: "offerta", inputs: FormElementOfferte},
    ]

    return(
        <div className={props.page === 4? "" : "hidden"}>
            <button id="add-button-off" className={style["add-button"]} onClick={add}>Nuova Offerta</button>
            <Table elimina={(id)=>elimina(id)} modifica={(id)=>modifica(id)} columnName={columnName} data={offerte}/>
            <Form btnAdd={btnAdd} onClose={closeAdd} onSave = {saveItem} formElemnts={formElements}/>
        </div>
    )
}

export default Offerte