import React from "react";
import style from './css/Table.module.css';

const Table = (props) => {



    function check() {
        const checkBoxs = document.querySelectorAll('input[attivazione="1"]');
        checkBoxs.forEach(checkBox => {
            checkBox.checked = true
        })
    }

    return (
        <table className={style['table'] && style['threeDMode']}>
            <thead>
                <tr>
                    {props.columnName.map(header => <th key={header}>{header}</th>)}
                </tr>
            </thead>
            <tbody>
                {props.data.map(row => (
                    <tr key={row.id}>
                        {Object.values(row).slice(1).map((value, index) => {
                            /* l'elemento per l'attivazione sarà sempre l'ultimo elemento dell'oggetto */
                            if (index === Object.values(row).slice(1).length - 1) {
                                setTimeout(check, 100)
                                return (
                                    <td className={style['td-has-switch']} key={index} >
                                        <label className={style["switch"]}>
                                            <input id={"active" + row.id} type="checkbox" attivazione={value}></input>
                                            <span className={style["slider"] + " " +style["round"]}></span>
                                        </label>
                                    </td>
                                )
                            } else {
                                return (
                                    <td key={index}>{value}</td>
                                )
                            }

                        })
                        }
                        <td>
                            <button onClick={() => props.elimina(row.id)}>E</button>
                            <button onClick={() => props.modifica(row.id)}>M</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default Table