import { useState } from 'react';
import './App.css';
import Menu from './components/menu/Menu';
import Page from './components/pages/Page';
import TopBar from './components/top/TopBar';


function App() {

    const [page, setPage] = useState(0);

    return (
        <div className="app">
            <Menu logo="https://www.comolake.com/images/logo-footer.png" renderPage={data => setPage(data)} />

            <div className='flex-dir-col'>
                <TopBar title={page} />
                <Page page={page}/>
            </div>
        </div>
    );
}

export default App;